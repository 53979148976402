import { copyToClipboard } from 'quasar'

export function fr_copyToClipboard(
  text: string,
  showNotification = true,
  showCopiedTextInNotification = true
) {
  copyToClipboard(text).then(() => {
    if (showNotification) {
      notifyMessage(
        `${showCopiedTextInNotification ? text + ' ' : ''}Copied To Clipboard`,
        'green',
        'fad fa-clipboard'
      )
    }
  })
}
